import React, { useState } from 'react'
import Header from './Header'
import Section1 from './HomeSection/Section1'
import Section2 from './HomeSection/Section2'
import Section3 from './HomeSection/Section3'
import Section4 from './HomeSection/Section4'
import Section6 from './HomeSection/Section6'
import Section7 from './HomeSection/Section7'
import Section5 from './HomeSection/Section5'
import Section8 from './HomeSection/Section8'
import Section9 from './HomeSection/Section9'
import Section10 from './HomeSection/Section10'
import Section11 from './HomeSection/Section11'
import Section12 from './HomeSection/Section12'
import Section13 from './HomeSection/Section13'
import Section14 from './HomeSection/Section14'
import Footer from './Footer'
import FormModal from './FormModal'
import 'react-medium-image-zoom/dist/styles.css'
import { ToastContainer } from 'react-toastify'
import BuilderSection from './HomeSection/BuilderSection'

const Home = () => {
   const [modalShow, setModalShow] = useState(false);

   const handleShow = () => {
      setModalShow(true)
   }

   return (
      <>
         <ToastContainer />
         <Header />
         <Section1 />
         <Section2 handleShow={handleShow} />
         <Section3 />
         <Section4 handleShow={handleShow} />
         <Section5 />
         <Section6 />
         <Section7 handleShow={handleShow} />
         <Section8 />
         {/* <Section9 /> */}
         <Section10 handleShow={handleShow} />
         <Section11 />
         <Section12 />
         <Section13 />
         <BuilderSection />
         <Section14 />
         <Footer />
         <FormModal modalShow={modalShow} setModalShow={setModalShow} />
      </>
   )
}

export default Home