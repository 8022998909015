import React, { useState } from 'react'
import FooterTop from "../Images/FooterTop.png"
import { MdOutlineCardTravel, MdOutlineDeblur, MdOutlineLocationOn, MdOutlineMail, MdOutlinePhone } from 'react-icons/md'
import { Form } from 'react-bootstrap'
import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from 'react-icons/bs'
import { TbWorldWww } from 'react-icons/tb'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Logo from "../Images/Logo/logo 2.png"
import LogoBuilder from "../Images/Logo/sohan group.png"
import LogoAspire from "../Images/Logo/aspireLogo.png"
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'

const Footer = () => {
   const [form, setForm] = useState({
      email: "",
      name: "",
      phone: "",
      message: "",
   });
   const [value, setValue] = useState()
   const navigate = useNavigate()
   console.log(form)
   const [loading, setLoading] = useState(false)
   const handleFormChange = (e) => {
      const { name, value } = e.target;
      setForm((prevForm) => ({
         ...prevForm,
         [name]: value,
      }));
   };
   const handleFormSubmit = async (e) => {
      e.preventDefault();
      if (value.length < 9) {
         toast.error("Please Enter a valid Phone Number")
         return;
      }
      setLoading(true)
      try {
         const data = {
            email: form.email,
            name: form.name,
            phone: value,
            message: form.message
         }
         const name = form.name.split(" ")

         const data2 = [
            {
               Attribute: "FirstName",
               Value: name[0],
               Fields: null
            },
            {
               Attribute: "LastName",
               Value: name[1],
               Fields: null

            },
            {
               Attribute: "EmailAddress",
               Value: form.email,
               Fields: null

            },
            {
               Attribute: "Phone",
               Value: value,
               Fields: null

            },
            {
               Attribute: "mx_Projects_Name",
               Value: "Sohan Exotica",
            },
            {
               Attribute: "Source",
               Value: "Google Ads"
            }

         ]
         const response = await axios.post(
            "https://api.aspireprop.com/cn/addSohanEnquiry",
            data
         );
         const response2 = await axios.post(
            "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r090d222f670409601d84d6a12987ce40&secretKey=aa4c6dd7b5d812f03c24f57cb79678f102cd55f7",
            data2
         );
         console.log("Enquiry submitted:", response.data);
         setForm({
            email: "",
            name: "",
            phone: "",
            message: "",
         });
         setValue()
         navigate('/thank-you')
      } catch (err) {
         alert("Please Try Again");
      }
      setLoading(false)
   };
   return (
      <div className='mt-5 d-flex align-items-center justify-content-center  flex-column section-15'>
         <img className='w-100' src={FooterTop} alt="poster" />
         <div className="footer">
            <div className="px-3 col-12 d-flex flex-wrap pb-3 pt-5">
               <div className="col-12 col-md-8  d-flex flex-wrap">
                  <div className="col-12 col-sm-6 col-md-6 px-2">
                     <div className="d-flex p-0 my-3 px-1 mx-4">
                        <img className='logo me-4' src={LogoBuilder} alt="logo" />
                        <img className='logo' src={Logo} alt="logo" />
                     </div>
                     <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlineLocationOn />
                        </div>
                        <div>
                           <p>
                              Rajiv Gandhi Nagar Road, Sahakar Nagar,
                              Kodigehalli, Bengaluru, Karnataka 560091
                           </p>
                        </div>
                     </div>
                     {/* <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlineMail />
                        </div>
                        <div>
                           <p>
                              hello@aspireprop.com
                           </p>
                        </div>
                     </div> */}
                     {/* <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlinePhone />
                        </div>
                        <div>
                           <p>
                              <a style={{textDecoration:"none", height:"0" ,display:"flex"}} target="_blank" href="tel:9886660229"><p className='mb-0'>
                              +91 98866 60229</p></a>
                              <br />
                              <a style={{textDecoration:"none"}} href="tel:9513319002" target="_blank"><p className='mb-0'>+91 95133 19002</p></a>
                              
                           </p>
                        </div>
                     </div> */}

                  </div>
                  <div className="col-12 col-sm-6 col-md-6 px-2">
                     <div className="d-flex p-0 my-3 px-1 mx-4">
                        <img className='logo' src={LogoAspire} alt="logo" />
                     </div>
                     <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlineLocationOn />
                        </div>
                        <div>
                           <p>
                              No. 45/11, First Floor, North Park Road, Kumara Park East, Bengaluru, Karnataka 560001
                           </p>
                        </div>
                     </div>
                     {/* <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlineMail />
                        </div>
                        <div>
                           <p>
                              hello@aspireprop.com
                           </p>
                        </div>
                     </div> */}
                     <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlinePhone />
                        </div>
                        <div>
                           <p>
                              <a style={{ textDecoration: "none", height: "0", display: "flex" }} href="tel:+919886660229"><p className='mb-0'>
                                 +91 98866 60229</p></a>
                              <br />
                              <a style={{ textDecoration: "none" }} href="tel:+919513319002"><p className='mb-0'>+91 95133 19002</p></a>

                           </p>
                        </div>
                     </div>
                     <div className="d-flex p-0">
                        <div className='me-2'>
                           <TbWorldWww />
                        </div>
                        <div>
                           <a target="_blank" style={{ textDecoration: "none" }} href="https://aspireprop.com/">
                              <p className='text-wrap'>

                                 www.aspireprop.com
                              </p></a>
                        </div>
                     </div>
                  </div>

                  {/* <div className="col-12 col-sm-6 col-md-4 px-2">
                     <div className='d-flex mb-2 justify-content-evenly'>
                        <BsInstagram size={32} />
                        <BsLinkedin size={32} />
                        <BsFacebook size={32} />
                        <BsYoutube size={32} />

                     </div>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam neque sint delectus eum quidem hic minus nihil, dolore quas. Magnam exercitationem iste perspiciatis ipsam fugiat, officia modi ipsa itaque aliquid mollitia fugit, unde quia, beatae facilis ea earum doloribus. Saepe quod, reprehenderit eum distinctio error corrupti aliquid tempora sed commodi?</p>
                  </div> */}
                  <div className="col-12 d-flex d-md-none flex-column d-md-flex px-2">
                     <div>
                        <h6 className='text-yellow'>
                           Enquiry Form
                        </h6>
                     </div>
                     <Form onSubmit={handleFormSubmit}>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                           <Form.Control required name="name" onChange={handleFormChange} value={form.name} type="text" placeholder="Enter Your Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">

                           <Form.Control type="email" name="email" onChange={handleFormChange} value={form.email} required placeholder="Enter Your Email" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                           <PhoneInput
                              className="input w-100 phone-input"
                              inputProps={{
                                 name: 'phone',
                                 required: true,
                                 // autoFocus: true
                              }}
                              country={'in'}
                              value={value}
                              onChange={(e) => setValue(e)}
                           // onChange={(e) => setValue(e.target.value)}
                           />
                        </Form.Group>
                        <Form.Group className="mb-0" controlId="formBasicPassword">
                           <Form.Control type="text" name="message" onChange={handleFormChange} value={form.message} required placeholder="Enter Your Query" />
                        </Form.Group>

                        <button style={{ width: "150px" }} disabled={loading} className="btn-yellow" type="submit">
                           {loading ? ("Loading...") : ("Submit")}
                        </button>
                     </Form>
                  </div>
                  <div className="col-12 mt-4 px-2">
                     <h6>Disclaimer</h6>
                     <p className='text-justify'>The content mentioned in this website is for information purposes only, prices mentioned are subject to change without notice & properties mentioned are subject to availability. Images are for representation purposes only. This is not the official website. This website belongs to the official channel partner of Sohan Developers. We may also send updates to the mobile email id registered with us. </p>
                     <p>All Rights Reserved.</p>
                     <div className="d-flex p-0">
                        <div className='me-2'>
                           <MdOutlineCardTravel />
                        </div>

                        <div>
                           <p className='text-wrap'>
                              PRM/KA/RERA/1251/309/PR/181109/002131
                           </p>
                        </div>
                     </div>
                  </div>

               </div>
               <div className="col-4 mt-3 d-none d-md-flex flex-column px-2">
                  <div className='mt-3 mb-2'>
                     <h6 className='text-yellow'>
                        Enquiry Form
                     </h6>
                  </div>
                  <Form onSubmit={handleFormSubmit}>

                     <Form.Group className="mb-5" controlId="formBasicPassword">

                        <Form.Control required name="name" onChange={handleFormChange} value={form.name} type="text" placeholder="Enter Your Name" />
                     </Form.Group>
                     <Form.Group className="mb-5" controlId="formBasicEmail">

                        <Form.Control type="email" name="email" onChange={handleFormChange} value={form.email} required placeholder="Enter Your Email" />
                     </Form.Group>

                     <Form.Group className="mb-5" controlId="formBasicPassword">

                        <PhoneInput
                           className="input w-100 phone-input"
                           inputProps={{
                              name: 'phone',
                              required: true,
                              // autoFocus: true
                           }}
                           country={'in'}
                           value={value}
                           onChange={(e) => setValue(e)}
                        // onChange={(e) => setValue(e.target.value)}
                        />
                     </Form.Group>
                     <Form.Group className="mb-4" controlId="formBasicPassword">

                        <Form.Control type="text" name="message" onChange={handleFormChange} value={form.message} required placeholder="Enter Your Query" />
                     </Form.Group>

                     <button style={{ width: "150px" }} disabled={loading} className="btn-yellow" type="submit">
                        {loading ? ("Loading...") : ("Submit")}
                     </button>
                  </Form>
               </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between px-5 py-3">
               <div className="col-6 col-sm-4">
                  <p>Copyright 2023</p>
               </div>
               <Link to={'/privacy-policy'} className="col-6 col-sm-4 text-center">
                  <h6>Privacy Policy</h6>
               </Link>
               <div className="col-0  col-sm-4"></div>
            </div>
         </div>
      </div>
   )
}

export default Footer