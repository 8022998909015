import React from 'react'
import { Accordion } from 'react-bootstrap'
const Section14 = () => {
   return (
      <div id="faqs" className='my-5 d-flex align-items-center justify-content-center  flex-column section-14 '>
         <h4 className="mb-5 text-center">
            Frequently Asked <span> <b><i>Questions</i></b></span>
         </h4>
         <div className="col-11 col-sm-9 col-md-7">
            <Accordion defaultActiveKey="0" flush>
               <Accordion.Item className='mb-3' eventKey="0">
                  <Accordion.Header>
                     <h6 className=" mb-0">What is the Sohan Exotica Project?</h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Sohan Exotica Project is a residential development offering luxurious 3 and 4 BHK flats/apartments in Sahakar Nagar, Bangalore.
                  </Accordion.Body>
               </Accordion.Item >
               <Accordion.Item className='mb-3' eventKey="1">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                        Where is the Sohan Exotica Project located?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Sohan Exotica Project is located in Sahakar Nagar, a well-established and sought-after neighborhood in Bangalore.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="2">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                        Are there any 4 BHK apartments available in Sahakar Nagar?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Yes, the Sohan Exotica Project offers 4 BHK apartments in Sahakar Nagar.
                  </Accordion.Body>
               </Accordion.Item >
               <Accordion.Item className='mb-3' eventKey="3">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                        Are there any 3 BHK flats available for sale in the Sohan Exotica Project?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Yes, there are 3 BHK flats for sale available in Sohan exotica.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="4">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                        What are the key features of the 3 BHK luxury apartments in Bangalore?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     The 3 BHK luxury apartments in Sohan Exotica Project come with high-quality finishes, spacious interiors, modern fittings, and premium amenities, providing an upscale living experience in Bangalore.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="5">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                        What makes the Sohan Exotica Project stand out from other apartments in Sahakar Nagar?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     The Sohan Exotica Project stands out due to its luxurious design, modern amenities, prime location in Sahakar Nagar, and quality construction.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="6">
                  <Accordion.Header>
                     <h6 className=" mb-0">

                        Are the apartments in Sahakar Nagar equipped with luxury amenities?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Yes, Sohan Exotica Project offers luxury amenities to enhance your lifestyle. These may include a swimming pool, clubhouse, gymnasium, landscaped gardens, and more.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="7">
                  <Accordion.Header>
                     <h6 className=" mb-0">

                        Can I book a site visit or get more information about Sohan Exotica?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Absolutely! To book a site visit or get more detailed information about Sohan Exotica, you can contact our sales team through their official website or by calling their designated sales helpline. Plus you can  read Sohan Exotica Review’s gathering insights from people who have already experienced it.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="8">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                        Is Sohan Exotica a RERA registered project?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Yes, Sohan Exotica is a RERA registered project, complying with all the necessary regulations and guidelines, ensuring transparency and security for homebuyers.
                  </Accordion.Body>
               </Accordion.Item>
            </Accordion>
         </div>
      </div>
   )
}

export default Section14