import React, { useEffect, useState } from 'react'
import Poster from "../../Images/banner.png"
import PosterMobile from "../../Images/banner-mobile.png"
const Section7 = ({ handleShow }) => {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
   useEffect(() => {
     const handleResize = () => {
       setWindowWidth(window.innerWidth);
     };
 
     window.addEventListener("resize", handleResize);
 
     // Clean up the event listener
     return () => {
       window.removeEventListener("resize", handleResize);
     };
   }, []);
   return (
      <>
         <div className='my-5 d-flex align-items-center justify-content-center  flex-column section-7'>

            <h4 className='mb-5 mt-0 mt-md-5  text-center px-0 px-sm-5 mx-3 mx-md-5'>
               Your Chance to Embrace Grand Living <br className='d-none d-md-flex' /><span><b><i>
                  Sohan Exotica's Special Offer Beckons
               </i></b>
               </span>
            </h4>
            <div className="col-12 d-flex justify-content-end">
               {/* <div onClick={handleShow} className="btn-yellow living">
                  Know More
               </div> */}
               {
                  windowWidth>=850?(<img onClick={handleShow} style={{ minHeight: "200px" }} className=' col-11' src={Poster} alt="" />):(<img onClick={handleShow} style={{ minHeight: "130px" }} className=' col-11' src={PosterMobile} alt="" />)
               }
               
            </div>
         </div>
         <div className="my-5">

         </div>
      </>
   )
}

export default Section7