import React, { useState } from 'react'
import IMG1 from "../../Images/FloorPlans/3 BHK (Block A).webp"
import IMG2 from "../../Images/FloorPlans/3 BHK (Block B).webp"
import IMG3 from "../../Images/FloorPlans/3 BHK (Block C).webp"
import IMG4 from "../../Images/FloorPlans/4 BHK Duplex (Block A).webp"
import IMG5 from "../../Images/FloorPlans/4 BHK Duplex (Block A.1).webp"
import IMG6 from "../../Images/FloorPlans/3 & 4 BHK Duplex  (Block A & D).webp"
import Zoom from 'react-medium-image-zoom'
const Section6 = () => {
   const [selectedImage, setSelectedImage] = useState(IMG1)
   const [selectedUnit, setSelectedUnit] = useState("3 BHK (Block A)")
   const imageUrls = [
      {
         url: IMG1,
         unit: "3 BHK (Block A)",
      },
      {
         url: IMG2,
         unit: "3 BHK (Block B)",
      },
      {
         url: IMG3,
         unit: `3 BHK (Block C)`,
      },
      {
         url: IMG4,
         unit: "4 BHK Duplex (Block A)",
      },
      {
         url: IMG5,
         unit: "4 BHK Duplex (Block A.1)",
      },
      {
         url: IMG6,
         unit: "3 & 4 BHK Duplex (Block A&D)",
      },
   ];
   const handleImageChange = (newImage, newUnit) => {
      setSelectedImage(newImage);
      setSelectedUnit(newUnit);
   };
   return (
      <div id="floorPlans" className='section-6 my-5'>
         <h4 className='mb-5 text-center'>

            Blueprints of Brilliance
            <br /> <span> <i><b>

               Your Grand Living Canvas
            </b></i></span>
         </h4>
         <div className="d-flex bg-dark-md flex-column flex-md-row ">
            <div className="col-12 col-md-4">
               <div className="floor-plans p-4">

                  <p className='text-align-justify'>
                     Take a glimpse of our wonderful 3 & 4 BHK apartments and get an idea of what your future homes would look like
                  </p>
                  <div className="my-3 d-flex flex-wrap justify-content-center">
                     {
                        imageUrls.map((imgUrl, i) => (
                           <div key={i} className="col-6 mb-4">
                              <div style={{
                                 cursor: "pointer",
                                 border:
                                    selectedUnit === imgUrl.unit ? "2px solid #d7ad61" : "",
                              }} onClick={() =>
                                 handleImageChange(imgUrl.url, imgUrl.unit)
                              }
                                 className="col-9 m-auto">
                                 <h6 style={{ minHeight: "40px" }} className='text-center'>{imgUrl.unit}</h6>
                                 <img className='col-12' src={imgUrl.url} alt="test" />
                              </div>

                           </div>
                        ))
                     }

                  </div>
               </div>
            </div>
            <div className="col-12 col-md-8 px-4 pt-0 pb-4 pt-md-4 floor-plans-poster">
               <div className="">
                  <Zoom>
                     <img src={selectedImage} alt="test" />
                  </Zoom>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Section6