import React from 'react'
import SliderQuotes from './SliderQuotes'
import { BiSolidQuoteAltLeft } from 'react-icons/bi'
const Section13 = () => {
   return (
      <div className='my-5 d-flex align-items-center justify-content-center flex-column section-13 py-5'>
         <div className='d-flex mb-3 flex-column flex-sm-row'>
            <BiSolidQuoteAltLeft className='me-2 align-self-center' size={100} />
            <div className='px-2'>
               <h4 className='mb-0 text-center'>
                  Where Dreams Find a Home
               </h4>
               <h5 className='text-center text-yellow'>
                  Hear from Sohan Exotica's Happy Residents
               </h5>
            </div>
         </div>
         <SliderQuotes />
         <div className="mb-3"></div>
      </div>
   )
}

export default Section13