import React from 'react'
import VerticalTabs from './VerticalTabs'

const Section10 = ({ handleShow }) => {
   return (
      <>
         <div id="amenities" className='my-5 d-flex align-items-center justify-content-center px-0 px-sm-5 mx-3 mx-md-5 section-8'>
            <h4 className='mt-0 mt-md-5 text-center'>
               40+ Grand Amenities <br />
               <span><b><i>
                  Designed to Elevate Your Luxurious Lifestyle
               </i></b></span>
            </h4>
         </div>
         <div className="my-5">
            <div className="tab-panel col-11  col-xl-10 mx-auto">
               <VerticalTabs handleShow={handleShow} />
            </div>
         </div>
      </>
   )
}

export default Section10