import React from 'react'

const Section2 = ({ handleShow }) => {
   return (
      <div id="overview" className='my-5 d-flex align-items-center justify-content-center px-3 px-sm-5 mx-0 mx-md-3 section-2'>

         <h4 className='mb-5 text-center'>
            Experience the Perfect Blend of <br className='d-none d-md-flex' /> <span>
               <b><i> Grandeur, Serenity, and Luxury
               </i></b>  </span>
         </h4>
         <p className="mb-3 mb-md-5 text-center">
            Step into a world of grandeur, serenity, and luxury at Sohan Exotica - the epitome of refined living in Sahakar Nagar, Bangalore. Embrace an unparalleled lifestyle in meticulously designed high-rise exotica apartments, where 70% open spaces welcome you to tranquility. With 40+ world-class amenities, including a lavish clubhouse, every detail has been crafted with uncompromising care to offer you an exceptional living experience near Hebbal.
         </p>

         <p className="mb-5 text-center">
            Escape the urban hustle and unwind in the lap of luxury, where every moment feels like a cherished escape.
         </p>

         <div className="home-sec-2">
            <div className='sec-2-1 mb-3 mb-sm-0'>
               <h5>4.2 Acres</h5>
               <p>Land Area</p>
            </div>
            <div className='sec-2-1 mb-3 mb-sm-0'>
               <h5>3 & 4</h5>
               <p>BHK</p>
            </div>
            <div className='sec-2-1 mb-3 mb-sm-0'>
               <h5>240</h5>
               <p>Units</p>
            </div>
            <div className='sec-2-1 mb-3 mb-sm-0'>
               <h5>1598 - 3013</h5>
               <p>Sqft</p>
            </div>
         </div>
         <div className=" mb-1 mt-5 d-flex justify-content-center">
            <div onClick={() => { handleShow() }} className="btn-green">Book a Site Visit</div>
         </div>
      </div>
   )
}

export default Section2
