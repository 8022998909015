import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiSolidQuoteAltLeft } from "react-icons/bi"
const SliderQuotes = () => {
   const [settings, setSettings] = useState({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "200px",
      autoplay: true,
      arrows: false,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   })
   const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
   });

   // we use the useEffect hook to listen to the window resize event
   // useEffect(() => {
   //    window.onresize = () => {
   //       setWindowSize({
   //          width: window.innerWidth,
   //          height: window.innerHeight,
   //       });
   //    };
   // }, [window.innerWidth]);
   // console.log(windowSize)

   useEffect(() => {
      if (windowSize.width <= 972) {
         setSettings(settings2)
      }
      else {
         setSettings(settings1)
      }
   }, [window.innerWidth])

   const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "200px",
      autoplay: true,
      arrows: false,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   };
   const settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "0px",
      autoplay: true,
      arrows: false,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   };
   return (
      <div className="col-12 slider-box">
         <Slider {...settings}>
            <div className="card-box-1">
               <div className="card-box ">
                  <BiSolidQuoteAltLeft size={40} />
                  <p className=" mb-4 text-start">

                     "I'm lucky to have invested in Sohan Exotica's residential project in Sahakar Nagar. It's an exceptional project that offers unmatched luxury and comfort. With convenient amenities and a peaceful neighborhood, I eagerly await moving into my dream home. Highly recommended!"<br />
                  </p>
                  <h6> - Aisha Reddy</h6>
                  {/* <p>Home Buyer</p> */}
               </div>
            </div>
            <div className="card-box-1">
               <div className="card-box ">
                  <BiSolidQuoteAltLeft size={40} />
                  <p className=" mb-4 text-start">
                  "Extremely satisfied with my 4 BHK home at Sohan Exotica. It's the perfect combination of comfort, style, and functionality. The location in Sahakar Nagar is fantastic, and I trust the Sohan Exotica team to deliver a top-notch residence."

                  </p>
                  <h6> - Kavya N. Kumar</h6>
                  {/* <p>Home Buyer</p> */}
               </div>
            </div>
            <div className="card-box-1">
               <div className="card-box ">
                  <BiSolidQuoteAltLeft size={40} />
                  <p className=" mb-4 text-start">
                     "Thrilled to book a 3 BHK apartment at Sohan Exotica in Sahakar Nagar. The location is ideal, offering a peaceful environment and excellent connectivity. The team has been incredibly supportive, and I'm excited to witness the project's transformation."<br />
                  </p>
                  <h6> - Karthik Nambiar</h6>
                  {/* <p>Home Buyer</p> */}
               </div>
            </div>
            <div className="card-box-1">
               <div className="card-box ">
                  <BiSolidQuoteAltLeft size={50} />
                  <p className=" mb-4 text-start">
                     "I'm thrilled with my investment in Sohan Exotica! The project offers impressive 3 and 4 BHK homes in a serene Sahakar Nagar location. With a stellar reputation for quality, I can't wait to move into my dream home."<br />
                  </p>
                  <h6> - Rajesh Pillai</h6>
                  {/* <p>Home Buyer</p> */}
               </div>
            </div>
         </Slider>
      </div>
   );
}

export default SliderQuotes
