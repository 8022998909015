import React from 'react'
import img1 from "../../Images/Section4/icons8-chevron-48.png"
import img2 from "../../Images/Section4/icons8-oak-tree-66.png"
import img3 from "../../Images/Section4/icons8-open-window-50.png"
const Section5 = () => {
   return (
      <div className='my-5 d-flex align-items-center justify-content-center px-3 px-sm-5 mx-0  flex-column section-5 py-5'>
         <h4 className='mb-5 text-center'>

            Exceptional Design that Harmonizes
            <br className='d-none d-md-flex' /> <span> <i><b>

               Grandeur, Serenity, and Luxurious Living
            </b></i></span>
         </h4>
         <div className="mx-0 mx-lg-3 mx-xl-5">
            <div className='mb-5 d-flex align-items-center justify-content-center'>
               <div className="col-2 col-md-1">

                  <img style={{width:"55px"}} src={img1} alt="logo" />
               </div>
               <p className='mb-0 col-9 col-md-10'>
                  <b>Every Unit is a Corner Unit</b> <br />Our residential project offers an exclusive living experience with two-sided open units. Each resident enjoys the privilege of a corner unit, ensuring unrivaled privacy and an abundance of natural light. Discover the comfort and luxury of living in a space that truly feels uniquely yours.
               </p>
            </div>
            <div className='mb-5 d-flex align-items-center justify-content-center'>
               <p className='mb-0  col-9 col-md-10'>
                  <b>Serene City Views</b><br /> Immerse yourself in the picturesque charm of the city's skyline. Our thoughtfully designed, high-rise buildings ensure unobstructed city views that stretch beyond the horizon. Nestled near the prestigious GKVK area, you'll find yourself surrounded by greenery, enhancing the overall serene ambiance.
               </p>
               <div className="col-2 col-md-1 d-flex justify-content-end">

               <img style={{width:"55px"}} src={img2} alt="logo" />
               </div>
            </div>
            <div className='mb-5 d-flex align-items-center justify-content-center'>
               <div className="col-2 col-md-1">

               <img style={{width:"55px"}} src={img3} alt="logo" />
               </div>
               <p className='mb-0 col-9 col-md-10' >
                  <b>Ultra-Bright Residences</b><br /> Step into a world of brightness and freshness within every corner of your new residence. Meticulously crafted to maximize light and ventilation, even the lower floors receive an ample amount of sunlight. Our innovative design philosophy guarantees that every room is bathed in natural light, fostering a cheerful and airy living environment.
               </p>
            </div>
         </div>
      </div>
   )
}

export default Section5
