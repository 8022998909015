import React from 'react'
import { Table } from 'react-bootstrap'
import { BiSolidLockAlt } from "react-icons/bi"

const Section4 = ({ handleShow }) => {
   return (
      <div id="configuration" className='my-5 d-flex align-items-center justify-content-center px-0 px-sm-5 mx-3 mx-md-5 section-4 '>
         <h4 className='mb-5 mt-0 mt-md-5 text-center'>

            Unleash the Power of Space: Uncover the Ultimate Advantage of our
            <span> <i><b>

                Highest Area Ratio Residences
            </b></i></span>
         </h4>

         <div className="col-12 col-sm-9  col-xl-8 py-0 py-md-4 m-auto price-box">
            <Table className='mb-0' striped bordered hover>
               <thead className='thead'>
                  <tr>
                     <th>Configuration</th>
                     <th>Sizes</th>
                     <th>Price</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td><p className='mb-0'>3 BHK</p></td>
                     <td><p className='mb-0'>1670-1847 sqft</p></td>
                     <td><div onClick={handleShow} className='get-price-btn'><BiSolidLockAlt />Unlock Price </div></td>
                  </tr>
                  <tr>
                     <td><p className='mb-0'>4 BHK</p></td>
                     <td><p className='mb-0'>2955-3013 sqft</p></td>
                     <td ><div onClick={handleShow} className='get-price-btn'><BiSolidLockAlt />Unlock Price</div></td>
                  </tr>
               </tbody>
            </Table>
         </div>
      </div>
   )
}

export default Section4
