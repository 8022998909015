import React from 'react'
import Section3Img from "../../Images/section-3.png"

const Section3 = () => {
   return (
      <div className='section-3 px-0 px-sm-5 '>
         <img className='px-0 px-sm-2 px-md-5' src={Section3Img} alt="banner" />
      </div>
   )
}

export default Section3