import React from 'react'
import Image2 from "../../Images/poster-home2.png"
import img1 from "../../Images/Gallery/1.png"
import img2 from "../../Images/Gallery/2.jpg"
import img3 from "../../Images/Gallery/3.jpg"
import img4 from "../../Images/Gallery/4.png"
import img5 from "../../Images/Gallery/5.png"
import img6 from "../../Images/Gallery/6.jpg"
import img7 from "../../Images/Gallery/7.png"
import img8 from "../../Images/Gallery/8.jpg"

const Section8 = () => {
   return (
      <>
         <div id="gallery" className='my-5 d-flex align-items-center justify-content-center px-0 px-sm-5 mx-3 mx-md-5 section-8'>
            <h4 className=' mt-0 mt-md-5 text-center'>
               A Visual Symphony of <br /> <span>
                  <b>
                     <i>
                        Grandeur, Serenity, and Luxury
                     </i></b></span>
            </h4>
         </div>
         <div className="my-5">
            <div className="img-gallery col-11 col-sm-10 col-md-9 mx-auto">
               <div className="row">
                  <div style={{ overflow: "hidden" }} className="col-4 p-0">
                     <div className="row">
                        <img className="col-12 img-1" src={img6} alt="gallery" />
                        <img className="col-12 img-2" src={img2} alt="gallery" />
                     </div>
                  </div>
                  <div className="col-8 p-0">
                     <img className="col-12 h-100 img-3" src={img8} alt="gallery" />
                  </div>
               </div>
               <div className="row">
                  <div className="col-8 p-0">
                     <img className="col-12 h-100 img-4" src={img1} alt="gallery" />
                  </div>
                  <div style={{ overflow: "hidden" }} className="col-4 p-0">
                     <div className="row">
                        <img className="col-12 img-5" src={img4} alt="gallery" />
                        <img className="col-12 img-6" src={img3} alt="gallery" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Section8