import React from 'react'
import img1 from "../../Images/Section11/Vector.png"
import img2 from "../../Images/Section11/Vector-1.png"
import img3 from "../../Images/Section11/Vector-2.png"
import img4 from "../../Images/Section11/Vector-3.png"
import img5 from "../../Images/Section11/Vector-4.png"
import img6 from "../../Images/Section11/Vector-5.png"
import img7 from "../../Images/Section11/Vector-6.png"
import img8 from "../../Images/Section11/Vector-7.png"
import img9 from "../../Images/Section11/Vector-8.png"
const Section11 = () => {
   return (
      <div id="usps" className='my-5 d-flex align-items-center justify-content-center px-3 px-sm-5 flex-column section-11 py-5'>

         <h4 className='mb-5 text-center'>
            Experience a Life of
            <br /><span><b>
               <i>
                  Grandeur, Serenity, and Unmatched Luxury
               </i></b></span>
         </h4>
         <div className="mx-0 mx-md-5 d-flex flex-wrap justify-content-center">
            <div className="col-6 col-sm-4 d-flex align-items-center  flex-column text-center px-3 px-md-5">
               <img src={img1} alt="poster" />
               <h6 className="yellow">Exquisite Residential Paradise</h6>
               <p>Immerse yourself in the epitome of luxurious living, where every detail is a masterpiece.</p>
            </div>
            
            <div className="col-6 col-sm-4 d-flex align-items-center  flex-column text-center px-3 px-md-5">
               <img src={img3} alt="" />
               <h6 className="yellow">Thoughtfully Crafted Homes</h6>
               <p>Experience a harmonious blend of design and functionality, tailored to fulfill your every desire.</p>
            </div>
            <div className="col-6 col-sm-4 d-flex align-items-center  flex-column text-center px-3 px-md-5">
               <img src={img2} alt="" />
               <h6 className="yellow">Prime Location</h6>
               <p>Embrace the prestige of Sahakar Nagar's prime address, symbolizing elegance and distinction.</p>
            </div>
            <div className="col-6 col-sm-4 d-flex align-items-center  flex-column text-center px-3 px-md-5">
               <img src={img7} alt="" />
               <h6 className="yellow">Inspired by Nature</h6>
               <p>Awaken your senses to the enchanting symphony of nature, creating a blissful living experience.</p>
            </div>
            <div className="col-6 col-sm-4 d-flex align-items-center  flex-column text-center px-3 px-md-5">
               <img src={img5} alt="" />
               <h6 className="yellow">Unparalleled Indulgence</h6>
               <p>Embark on a journey of opulence and unmatched amenities, where luxury knows no bounds.</p>
            </div>
            <div className="col-6 col-sm-4 d-flex align-items-center  flex-column text-center px-3 px-md-5">
               <img src={img6} alt="" />
               <h6 className="yellow">Unlock the Potential of Space</h6>
               <p>Reveal the ultimate advantage of our residences with the highest area ratio, maximizing every inch.</p>
            </div>

            <div className="col-6 col-sm-4 d-flex align-items-center  flex-column text-center px-3 px-md-5">
               <img src={img4} alt="" />
               <h6 className="yellow">Serene & Tranquil Environment</h6>
               <p>Discover solace and serenity amidst lush green surroundings, offering a haven of peace.</p>
            </div>

            <div className="col-6 col-sm-4 d-flex align-items-center  flex-column text-center px-3 px-md-5">
               <img src={img8} alt="" />
               <h6 className="yellow">Ascend to Extraordinary Living</h6>
               <p>Elevate your lifestyle to extraordinary heights of grandeur and sophistication..</p>
            </div>
            <div className="col-6 col-sm-4 d-flex align-items-center  flex-column text-center px-3 px-md-5">
               <img src={img9} alt="" />
               <h6 className="yellow">Where Dreams Take Flight</h6>
               <p>Be captivated by breathtaking city views that will leave you breathless.</p>
            </div>
         </div>
      </div>
   )
}

export default Section11