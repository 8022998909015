import React from 'react'
import { Accordion } from 'react-bootstrap'

const Section12 = () => {
   return (
      <div id="location" className='my-5 d-flex align-items-center justify-content-center px-3 px-sm-5 flex-column section-12'>

         <h4 className='mb-5 text-center'>
            Discover Tranquility Amidst Urban Chaos,<br className='d-none d-md-flex' /> <span> <b><i>
               Your Serene Escape in the Heart of the City
            </i></b>
            </span>
         </h4>
         <div className="px-0 px-md-5 flex-column flex-sm-row d-flex col-12 ">
            <div className="col-12 mb-5 mb-sm-0 col-sm-5 px-2">
               <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item className='mb-3' eventKey="0">
                     <Accordion.Header>
                        <h6 className=" mb-0">Tech Hubs</h6>
                     </Accordion.Header>
                     <Accordion.Body className="text-start">
                        <p>L & T Tech Park - 1.2 Km</p>
                        <p>Kirloskar Tech Park - 5.4 Km</p>
                       
                        <p>Manyata Tech Park - 8.5 Km</p>
                        <p>Bharatiya City - 11.2 Km</p>
                     </Accordion.Body>
                  </Accordion.Item >
                  <Accordion.Item className='mb-3' eventKey="1">
                     <Accordion.Header>
                        <h6 className=" mb-0">
                           Shopping Malls
                        </h6>
                     </Accordion.Header>
                     <Accordion.Body className="text-start">
                        <p>Phoenix Mall of Asia - 2.5 Km</p>
                        <p>Esteem Mall - 5.2 Km</p>
                        <p>The Galleria Mall - 5.3 Km</p>
                        <p>Elements Mall - 8.8 Km</p>
                        <p>Bhartiya Mall - 10 Km</p>
                     </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className='mb-3' eventKey="2">
                     <Accordion.Header>
                        <h6 className=" mb-0">
                           Schools
                        </h6>
                     </Accordion.Header>
                     <Accordion.Body className="text-start">
                        <p>Sree Vinayaka Vidya Kendra School - 900 mtr</p>
                        <p>TRIO International School - 1.7 Km</p>
                        <p>Ryan International School - 9 Km</p>
                        <p>Canadian International School - 9.2 Km</p>
                     </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className='mb-3' eventKey="3">
                     <Accordion.Header>
                        <h6 className=" mb-0">
                           Hospitals
                        </h6>
                     </Accordion.Header>
                     <Accordion.Body className="text-start">
                        <p>Medstar Speciality Hospital - 2 km</p>
                        <p>Aster CMI Hospital - 3.6 Km</p>
                        <p>Golden Square Hebbal - 3.8 Km</p>
                        <p>Manipal Hospital Hebbal - 3.9 Km</p>
                     </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                     <Accordion.Header>
                        <h6 className=" mb-0">
                           Landmarks
                        </h6>
                     </Accordion.Header>
                     <Accordion.Body className="text-start">
                        <p>Hebbal - 6 Km</p>
                        <p>Jakkur Lake - 6.6 Km</p>
                        <p>Yelahanka Air Force Base - 9.2 Km
                        </p>
                        <p>MG Road - 13.6 Km</p>
                        <p>Kempegowda International Airport - 33.2 Km</p>
                     </Accordion.Body>
                  </Accordion.Item>
               </Accordion>
            </div>
            <div className="col-12 col-sm-7 px-2">
               <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5510076820706!2d77.57548109999999!3d13.064226800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19ab1c0540c1%3A0xe927b76b986179f6!2sSohan%20Exotica!5e0!3m2!1sen!2sin!4v1693304355904!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
               ></iframe>
            </div>
         </div>
      </div>
   )
}

export default Section12