import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Tab, Tabs, Typography } from '@mui/material';
import icon1 from "../../Images/Section10/High Jump.png"
import icon2 from "../../Images/Section10/Airport Security.png"
import icon3 from "../../Images/Section10/Badge.png"
import icon4 from "../../Images/Section10/Badminton Player.png"
import icon5 from "../../Images/Section10/Basketball Player.png"
import icon6 from "../../Images/Section10/Blur.png"
import icon7 from "../../Images/Section10/Cricketer.png"
import icon8 from "../../Images/Section10/Disco Ball.png"
import icon9 from "../../Images/Section10/Dumbbell.png"
import icon10 from "../../Images/Section10/Electrical.png"
import icon12 from "../../Images/Section10/Fire Alarm.png"
import icon13 from "../../Images/Section10/Fire Sprinkler.png"
import icon14 from "../../Images/Section10/Garden.png"
import icon15 from "../../Images/Section10/Gas.png"
import icon16 from "../../Images/Section10/Guru.png"
import icon17 from "../../Images/Section10/Lap Pool.png"
import icon19 from "../../Images/Section10/Outdoor Parking.png"
import icon20 from "../../Images/Section10/Parking.png"
import icon21 from "../../Images/Section10/Party.png"
import icon22 from "../../Images/Section10/Rainwater Catchment.png"
import icon23 from "../../Images/Section10/Roller Skating.png"
import icon24 from "../../Images/Section10/Seat Heater.png"
import icon25 from "../../Images/Section10/Seesaw.png"
import icon26 from "../../Images/Section10/Stage.png"
import icon27 from "../../Images/Section10/Taxi Rank.png"
import icon28 from "../../Images/Section10/Tools.png"
import icon29 from "../../Images/Section10/Tree Planting.png"
import icon30 from "../../Images/Section10/Wall Mount Camera.png"
import icon31 from "../../Images/Section10/Waste.png"
import icon32 from "../../Images/Section10/Water Pipe.png"
import icon33 from "../../Images/Section10/Weber.png"
import icon34 from "../../Images/Section10/icons8-golf-50.png"
import icon35 from "../../Images/Section10/icons8-scotch-tape-50.png"
import icon36 from "../../Images/Section10/icons8-pavillion-64.png"
import icon37 from "../../Images/Section10/icons8-musical-80.png"
import icon38 from "../../Images/Section10/icons8-child-safe-zone-50.png"
import icon39 from "../../Images/Section10/icons8-yoga-50.png"
import icon40 from "../../Images/Section10/icons8-gazebo-60.png"
import icon41 from "../../Images/Section10/icons8-dog-park-64.png"
import icon42 from "../../Images/Section10/icons8-chess-50.png"
import icon43 from "../../Images/Section10/icons8-plaza-66.png"
import icon44 from "../../Images/Section10/icons8-walkway-96.png"
import icon45 from "../../Images/Section10/icons8-road-64 (1).png"
import icon46 from "../../Images/Section10/icons8-front-door-50.png"
import icon47 from "../../Images/Section10/icons8-no-entry-80.png"
import icon48 from "../../Images/Section10/icons8-transformer-48.png"
import icon49 from "../../Images/Section10/icons8-lawn-60.png"
import icon50 from "../../Images/Section10/icons8-scottish-thistle-60.png"
import icon51 from "../../Images/Section10/icons8-sand-castle-60.png"
import icon52 from "../../Images/Section10/icons8-kitchen-room-64.png"
import icon53 from "../../Images/Section10/icons8-path-50.png"
import icon54 from "../../Images/Section10/icons8-floating-guru-50.png"
import icon55 from "../../Images/Section10/icons8-children-80.png"
import icon56 from "../../Images/Section10/icons8-garden-sprinkler-50.png"
import icon57 from "../../Images/Section10/icons8-bench-64.png"
import icon58 from "../../Images/Section10/icons8-great-wall-64.png"

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`vertical-tabpanel-${index}`}
         aria-labelledby={`vertical-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
   };
}

export default function VerticalTabs({ handleShow }) {
   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <>

         <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 500, width: "100%" }}
            className="col-12 flex-column flex-md-row"
         >
            <Tabs
               orientation="vertical"
               value={value}
               className="col-12 col-md-4"
               onChange={handleChange}
               aria-label="Vertical tabs example"
               sx={{ borderRight: 1, borderColor: 'divider' }}
            >
               <Tab label="Health, Sport & Fitness" {...a11yProps(0)} />
               <Tab label="EnterTainment & Socialising" {...a11yProps(1)} />
               <Tab label="Convenience" {...a11yProps(2)} />
               <Tab label="Security" {...a11yProps(3)} />
               <Tab label="Eco Living" {...a11yProps(4)} />
               <div style={{ position: "absolute", left: "25%", bottom: "30px" }} onClick={handleShow} className="btn-yellow tabs d-none d-lg-flex">
                  Know More
               </div>
            </Tabs>
            <TabPanel className="image-section col-12 col-md-8" value={value} index={0}>
               <div className=" flex-wrap d-flex col-12">
                  <div className='d-flex  align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2 '>
                     <img src={icon1} alt="poster" />
                     <p>Aerobics Room</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon16} alt="poster" />
                     <p>Yoga/Meditation Room</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon17} alt="poster" />
                     <p>Swimming Pool</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon9} alt="poster" />
                     <p>Gymnasium</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon5} alt="poster" />
                     <p>Baskeball Court</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon4} alt="poster" />
                     <p>Badmintion Court</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon23} alt="poster" />
                     <p>Skating Rink</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon7} alt="poster" />
                     <p>Cricket Pitch</p>
                  </div>
                  {/* <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon7} alt="poster" />
                     <p>Infinity Swimming Pool</p>
                  </div> */}
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon34} alt="poster" />
                     <p>Mini Golf</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon35} alt="poster" />
                     <p>Hop Scotch</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon36} alt="poster" />
                     <p>Pavilion</p>
                  </div>
               </div>
            </TabPanel>
            <TabPanel className="image-section col-11 col-md-8" value={value} index={1}>
               <div className=" flex-wrap d-flex col-12">
                  <div className='d-flex  align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2 '>
                     <img src={icon33} alt="poster" />
                     <p>Barbecue Area</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon24} alt="poster" />
                     <p>Amphitheater</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon26} alt="poster" />
                     <p>Community Hall</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon8} alt="poster" />
                     <p>Club House</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon21} alt="poster" />
                     <p>Party Lawn</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon25} alt="poster" />
                     <p>Kids Play Area</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon37} alt="poster" />
                     <p>Outdoor Musical Garden</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon38} alt="poster" />
                     <p>Elders Chit Chat Zone</p>
                  </div>

                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon39} alt="poster" />
                     <p>Sunken Lawn Yoga</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon40} alt="poster" />
                     <p>Gazebo</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon41} alt="poster" />
                     <p>Pet Park</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon42} alt="poster" />
                     <p>Giant Chess</p>
                  </div>
               </div>
            </TabPanel>
            <TabPanel className="image-section col-11 col-md-8" value={value} index={2}>
               <div className=" flex-wrap d-flex col-12">

                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon20} alt="poster" />
                     <p>Covered Car Parking</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon28} alt="poster" />
                     <p>Maintenance Staff</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon22} alt="poster" />
                     <p>Water Storage</p>
                  </div>

                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon10} alt="poster" />
                     <p>Power Backup</p>
                  </div>

                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon27} alt="poster" />
                     <p>Visitor Parking</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon3} alt="poster" />
                     <p>Staff Quarter</p>
                  </div>
                  <div className='d-flex  align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2 '>
                     <img src={icon15} alt="poster" />
                     <p>Piped Gas Connection</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon6} alt="poster" />
                     <p>24X7 Water Supply</p>
                  </div>

                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon31} alt="poster" />
                     <p>Solid Waste Management</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon43} alt="poster" />
                     <p>Paved Plaza</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon45} alt="poster" />
                     <p>6.0M Wide Road</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon44} alt="poster" />
                     <p>1.5M Walkway</p>
                  </div>
               </div>
            </TabPanel>
            <TabPanel className="image-section col-11 col-md-8" value={value} index={3}>
               <div className=" flex-wrap d-flex col-12">
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon13} alt="poster" />
                     <p>Fire Sprinklers</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon12} alt="poster" />
                     <p>Fire Fighting System</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon30} alt="poster" />
                     <p>CCTV</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon19} alt="poster" />
                     <p>Reserved Parking</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon2} alt="poster" />
                     <p>Security Cabin</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon20} alt="poster" />
                     <p>Covered Car Parking</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon32} alt="poster" />
                     <p>Seawage Treatment Plant</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon46} alt="poster" />
                     <p>Entry & Exit Gate</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon47} alt="poster" />
                     <p>Block Entry</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon48} alt="poster" />
                     <p>Transformer Yard</p>
                  </div>
               </div>
            </TabPanel>
            <TabPanel className="image-section col-11 col-md-8" value={value} index={4}>
               <div className=" flex-wrap d-flex col-12">
                  <div className='d-flex  align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2 '>
                     <img src={icon29} alt="poster" />
                     <p>Landscaping & Tree Planting</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon14} alt="poster" />
                     <p>Manicured Garden</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon49} alt="poster" />
                     <p>Lawn</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon50} alt="poster" />
                     <p>Hop Scotch</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon51} alt="poster" />
                     <p>Sand Pit</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon52} alt="poster" />
                     <p>Kitchen Garden</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon53} alt="poster" />
                     <p>Reflexology Path</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon54} alt="poster" />
                     <p>Elders Yoga Lawn</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon55} alt="poster" />
                     <p>Kids Play on EPDM</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon56} alt="poster" />
                     <p>Outdoor Musical Garden</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon57} alt="poster" />
                     <p>Tree Court With Seater</p>
                  </div>
                  <div className='d-flex align-items-center flex-column  img-sec col-lg-3 col-6 col-sm-3 col-md-4 text-center px-2'>
                     <img src={icon58} alt="poster" />
                     <p>Waterbody With Feature Wall</p>
                  </div>
               </div>
            </TabPanel>
         </Box>
      </>
   );
}
