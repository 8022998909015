import React, { useState } from 'react'
import Slider from 'react-slick'
import img1 from "../../Images/Section13/Delivery Time.png"
import img2 from "../../Images/Section13/Facebook Like.png"
import img3 from "../../Images/Section13/Guarantee.png"
import img4 from "../../Images/Section13/Handshake.png"
import poster1 from "../../Images/Logo/Sohan yellow.png"
import poster2 from "../../Images/Section13/2.png"
import poster3 from "../../Images/Section13/3.png"
import poster4 from "../../Images/Section13/4.png"
const BuilderSection = () => {
   const [settings, setSettings] = useState({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "0px",
      autoplay: true,
      arrows: false,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   })
   const posters = [poster1, poster2, poster3, poster4];
   return (
      <div id="about-sohan" className='section-builder'>
         <h4 className='  text-center pb-5 mb-0'>
            Builders of Distinction <span><b><i> Homes of perfection</i></b>

            </span>
         </h4>
         <div className="col-12 d-flex flex-wrap flex-row align-items-center">

            <div className="col-12 d-flex justify-content-center col-md-6 px-3 px-md-2 px-lg-4 px-xl-5">
               <div className="col-12 col-md-10  pb-5 musings">
                  <Slider {...settings}>
                     {posters.map((data) => (
                        <div className="card-box-1">
                           <div className="card-box-img ">
                              <img src={data} alt="photo" />
                           </div>
                        </div>
                     ))}
                  </Slider>
               </div>

            </div>
            <div className="col-12 col-md-6 px-3 px-md-2 px-lg-4 px-xl-5 mt-4 mt-md-0">
               <div className='d-flex justify-content-between mb-4'>
                  <div className="box">
                     <img className="mb-2" style={{ width: "35px" }} src={img4} alt="logo" />
                     <h6 className='text-center'><span>Operational Since 2008</span></h6>
                     <p>Pioneering residential development since 2008, our decade-long journey has refined our housing solutions for you. </p></div>
                  <div className="box"><img className="mb-2" style={{ width: "35px" }} src={img2} alt="logo" /><h6 className='text-center'><span>10+ Successful Projects</span></h6>
                     <p>Over a decade, we've proudly delivered 10+ residential projects, showcasing our dedication to quality, innovation, and customer satisfaction. </p></div>
               </div>
               <div className='d-flex justify-content-between'>
                  <div className="box"><img className="mb-2" style={{ width: "35px" }} src={img1} alt="logo" /><h6 className='text-center'><span>Honesty and Integrity at the Core</span></h6>
                     <p>Honesty and integrity are the cornerstones of our foundation, fostering trust through transparent project processes.</p></div>
                  <div className="box"><img className="mb-2" style={{ width: "35px" }} src={img3} alt="logo" /><h6 className='text-center'><span>Expert Teams, Expert Results</span></h6>
                     <p>Our expert teams, comprising industry professionals, guarantee outstanding results, turning your dream home into a reality.</p></div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default BuilderSection